import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import * as moment  from 'moment';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { BRAND_ID_VALUE, thirty_Mnts_Interval, bakingPlanType } from '../../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { datePickerLocale } from '../../../locale/constant';
import { TimePeriodFormatter } from '../../languageDropdown/timePeriodFormatter';

var todaysDate = new Date();

$(function () {
	$('#selectTime').keypress(function (e) {
		var keyCode = e.which || e.keyCode;
		if (!(keyCode === 97 || keyCode === 109 || keyCode === 112 || keyCode === 48 || keyCode === 49
			|| keyCode === 50 || keyCode === 51 || keyCode === 52 || keyCode === 53 || keyCode === 54
			|| keyCode === 55 || keyCode === 56 || keyCode === 57 || keyCode === 58 || keyCode === 32)) {
			return false;
		}
	});
	
	$('#ScheduleDate').on('focus', function(e) { 
		e.preventDefault();
		$(this).blur();
	});
});
class softwareScheduleUpdate extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			Uploadedfile: {},
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			UpdateNow: true,
			ScheduleUpdate: false,
			startDate: "",
			Scheduledtime: "",
			MODEL_IDS: "",
			MODEL_NAMES: "",
			FILE_NAME: "",
			VERSION: "",
			UPLOADED_ON: "",
			TYPE: "",
			ID: "",
			maxdate: todaysDate,
		};

		this.mercoMenuAssign = this.mercoMenuAssign.bind(this);
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.ResetDatepicker = this.ResetDatepicker.bind(this);
	}

	getBrandId = () => {
		let brandName = localStorage.getItem("brandName");
		return BRAND_ID_VALUE[brandName] || 0;
	}

	componentWillMount = () => {
		let { MODEL_IDS, MODEL_NAMES, FILE_NAME, VERSION, UPLOADED_ON, TYPE, ID } = this.props.location;
		this.setState({
			MODEL_IDS: MODEL_IDS,
			MODEL_NAMES: MODEL_NAMES,
			FILE_NAME: FILE_NAME,
			VERSION: VERSION,
			UPLOADED_ON: UPLOADED_ON,
			TYPE: TYPE,
			ID: ID
		});
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		let Max_date = new Date(todaysDate);
		Max_date.setMonth(Max_date.getMonth() + 6)
		this.setState({
			maxdate: Max_date
		});

		if(this.state.UpdateNow && this.props.location.MODEL_NAMES !== undefined){
			$("#btnnext").addClass("btn-default-text activeState");
			$("#btnnext").removeClass("disabled");
		}
		$(".dropdownTimeOptions").hide();
		$("#selectTime").focus(function () {
			$(".dropdownTimeOptions").show();
		});
		var timeValue;
		$(document).click(function (e) {
			$(".dropdownTimeOptions").hide();
		});
		$('#timeSchedulePanel').click(function (e) {
			e.stopPropagation();
		});
		$(".dropdownTimeOptions > li > a").click(function (e) {
			e.preventDefault();
			timeValue = $(this).text();
			$("#selectTime").val(timeValue);
			$(".dropdownTimeOptions").hide();
		});
		$(".dropdownTimeOptions > li").click((e) => {
			e.preventDefault();
				$("#btnnext").addClass("btn-default-text activeState");
				$("#btnnext").removeClass("disabled");
		});
	}

	settimeselectonmenu = (e) => {
		if (this.state.ScheduleUpdate && this.props.location.MODEL_NAMES !== undefined && $("#ScheduleDate").val() !== "" && $("#selectTime").val() !== "") {
			$("#btnnext").addClass("btn-default-text activeState");
			$("#btnnext").removeClass("disabled");
		}
	}


	setscheduleUpdate = (event) => {
		if (event.target.value === "ScheduleUpdate") {
			this.setState({
				ScheduleUpdate: true,
				UpdateNow: false
			});
			$("#Schedulediv").removeClass("displaynone");
            if(this.props.location.MODEL_NAMES !== undefined && $("#ScheduleDate").val() !== "" && $("#selectTime").val() !== ""){
				$("#btnnext").addClass("btn-default-text activeState");
				$("#btnnext").removeClass("disabled");
			}
			else{
				$("#btnnext").removeClass("activeState");
				$("#btnnext").addClass("disabled");
			}
		}
		else if (event.target.value === "UpdateNow") {
			this.setState({
				ScheduleUpdate: false,
				UpdateNow: true
			});
			$("#Schedulediv").addClass("displaynone");
			if(this.props.location.MODEL_NAMES !== undefined){
				$("#btnnext").addClass("btn-default-text activeState");
				$("#btnnext").removeClass("disabled");
			}
	
		}
	}

	handleChange = (date) => {
		this.setState({
			startDate: date
		});
        if(this.state.ScheduleUpdate && this.props.location.MODEL_NAMES !== undefined && $("#selectTime").val() !== ""){
			$("#btnnext").addClass("btn-default-text activeState");
			$("#btnnext").removeClass("disabled");
		}
	}

	onChangeSchedule = (e) => {
		let idx = e.target.selectedIndex;
		let Scheduledtime = e.target.options[idx].text;
		this.setState({ Scheduledtime: Scheduledtime });
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
	}

	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	mercoMenuAssign = (e) => {
		this.setState({ loaderIconVisible: true });
		if(this.props.location.MODEL_NAMES !== undefined){
			if (this.state.ScheduleUpdate) {
				let softwareScheduleDate = $("#ScheduleDate").val();
				//Checks for Valid Time.
				let selectTime = $("#selectTime").val();
				let checkvalidTime = selectTime.match(/^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/);
	
				//Checks for Valid Date.
				let rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
				let dtArray = softwareScheduleDate.match(rxDatePattern);
				let isValidDate = true;
				if (dtArray !== null) {
					//Checks for mm/dd/yyyy format.
					let dtMonth = dtArray[1];
					let dtDay = dtArray[3];
					let dtYear = dtArray[5];
					if (dtMonth < 1 || dtMonth > 12) {
						isValidDate = false;
					}
					else if (dtDay < 1 || dtDay > 31) {
						isValidDate = false;
					}
					else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31) {
						isValidDate = false;
					}
					else if (dtMonth == 2) {
						let isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
						if (dtDay > 29 || (dtDay == 29 && !isleap)) {
							isValidDate = false;
						}
					}
				}
	
				//Checks for Date equal to greater than yesterday
				let TodayDate = new Date();
				TodayDate = TodayDate.getFullYear() + '-' + (TodayDate.getMonth() + 1) + '-' + TodayDate.getDate();
				TodayDate = new Date(TodayDate).getTime();
	
				let selecteddate = new Date(softwareScheduleDate);
				selecteddate = selecteddate.getFullYear() + '-' + (selecteddate.getMonth() + 1) + '-' + selecteddate.getDate();
				selecteddate = new Date(selecteddate).getTime();
	
				if (this.state.ScheduleUpdate && (selectTime === "" || softwareScheduleDate === "")) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							startDate: todaysDate,
							message: "KC1125"
						}
					});
				}
				else if (this.state.ScheduleUpdate && !isValidDate) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							startDate: todaysDate,
							message: "KC1128"
						}
					});
				}
				else if (this.state.ScheduleUpdate && !((TodayDate === selecteddate) || (TodayDate < selecteddate))) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							startDate: todaysDate,
							message: "KC1127"
						}
					});
				}
				else if (this.state.ScheduleUpdate && !checkvalidTime) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: "KC1126"
						}
					});
				}
				else {
					// Current Time start
					let today = new Date();
					let hours = today.getHours();
					let minutes = today.getMinutes();
					let ampm = hours >= 12 ? 'PM' : 'AM';
					hours = hours % 12;
					hours = hours ? hours : 12;
					minutes = minutes < 10 ? '0' + minutes : minutes;
					let strTime = hours + ':' + minutes + ' ' + ampm;
					let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
					let Currentstart = date + ' ' + strTime;
					// Current Time End
	
					//selected Time start
					let selecteddatetime = this.state.startDate;
					let selecteddate = selecteddatetime.getFullYear() + '-' + (selecteddatetime.getMonth() + 1) + '-' + selecteddatetime.getDate();
					let selectedstart = selecteddate + ' ' + selectTime;
					//selected Time end
	
					//Six Month Date Validation start
					var pastfuturedate = new Date(softwareScheduleDate);
					pastfuturedate = pastfuturedate.getFullYear() + '-' + (pastfuturedate.getMonth() + 1) + '-' + pastfuturedate.getDate();
					pastfuturedate = pastfuturedate + ' ' + selectTime;
					pastfuturedate = new Date(pastfuturedate).getTime();
	
					let max_sixmont = this.state.maxdate;
					max_sixmont = max_sixmont.getFullYear() + '-' + (max_sixmont.getMonth() + 1) + '-' + max_sixmont.getDate();
					max_sixmont = max_sixmont + ' ' + selectTime;
					max_sixmont = new Date(max_sixmont).getTime();
					//Six Month Date Validation end
	
					let Currenttime = new Date(Currentstart).getTime();
					let selectedtime = new Date(selectedstart).getTime();
	
	
					if (this.state.ScheduleUpdate && ((pastfuturedate > max_sixmont) || (pastfuturedate < Currenttime))) {
						this.setState({
							loaderIconVisible: false,
							error_modal: {
								open: true,
								startDate: todaysDate,
								message: "KC1125"
							}
						});
					}
					else if (this.state.ScheduleUpdate && ((Currenttime > selectedtime) || (Currenttime === selectedtime))) {
						const { formatMessage } = this.props.intl;
						this.setState({
							loaderIconVisible: false,
							error_modal: {
								open: true,
								message: formatMessage({ id: 'KC1124' }, { selectTime })
							}
						});
					}
					else {
						let scheduleDateTimeUtcFormat;
						if (this.state.ScheduleUpdate) {
							let Scheduledtime = selectTime;
							let sceduleDatewithoutString = new Date(this.state.startDate);
							const formatDate = moment(sceduleDatewithoutString, 'L').format('YYYY-MM-DD');
							const  concatDateTime = `${formatDate} ${Scheduledtime}`;
							scheduleDateTimeUtcFormat = moment(concatDateTime, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm');
						}
	
						this.props.history.push({
							pathname: "/assignUpdateList",
							scheduleDateTimeUtc: scheduleDateTimeUtcFormat,
							Type: this.state.ScheduleUpdate,
							MODEL_IDS: localStorage.getItem("modelIdValue"),
							MODEL_NAMES: this.state.MODEL_NAMES,
							FILE_NAME: this.state.FILE_NAME,
							VERSION: this.state.VERSION,
							UPLOADED_ON: this.state.UPLOADED_ON,
							TYPE: this.state.TYPE,
							ID: this.state.ID
						});
					}
				}
			}
			else {
				this.props.history.push({
					pathname: "/assignUpdateList",
					Type: this.state.ScheduleUpdate,
					MODEL_IDS: localStorage.getItem("modelIdValue"),
					MODEL_NAMES: this.state.MODEL_NAMES,
					FILE_NAME: this.state.FILE_NAME,
					VERSION: this.state.VERSION,
					UPLOADED_ON: this.state.UPLOADED_ON,
					TYPE: this.state.TYPE,
					ID: this.state.ID
				});
			}
		}
		else{
			this.props.history.push(`/softwareAvailableUpdates`);
		}
		this.setState({ loaderIconVisible: false });
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { loaderIconVisible } = this.state;
		let { success_modal, error_modal, TYPE } = this.state;
		return (

			<div id="adminWrapper">

				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">

					<div class="softwareUpdateWrapper">
						<div class="adminBgHeader">
							<ul>
								<li>
								{TYPE == bakingPlanType ? <Link to="/bakingPlan" title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</Link>
								: <Link to="/softwareAvailableUpdates" title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</Link> }
								</li>
								<li className="txtboldmsg"> 
									<FormattedMessage id="KC0665"/>
				            	</li>
								<li>
									<a id="btnnext" href="javascript:void(0)" className="disabled" onClick={(e) => this.mercoMenuAssign(e)} title={formatMessage({ id: 'KC0543' })}><FormattedMessage id="KC0543"/></a>
								</li>
							</ul>
						</div>

						<div class="scheduleUpdateWrapper">
							<div class="scheduleUpdateWrapperL">
								<div class="selectedSWscheduleUpdate">
									<div class="selectedBrandLogo"><img src={this.getBrandId() === 1 ? require("../../../images/logos/Merrychef_Color.svg").default : this.getBrandId() === 6 ? require("../../../images/logos/Convotherm_Color.svg").default
									: this.getBrandId() === 3 ? require("../../../images/logos/Frymaster_Color.svg").default : this.getBrandId() === 5 ? require("../../../images/logos/manitowoc.png") : this.getBrandId() === 7 ? require("../../../images/logos/Delfield_Color.svg").default
									: this.getBrandId() === 8 ? require("../../../images/logos/Multiplex_Color.svg").default : this.getBrandId() === 9 ? require("../../../images/logos/Garland_Color.svg").default : this.getBrandId() === 10 ? require("../../../images/logos/Lincoln_Color.svg").default : this.getBrandId() === 11 ? require("../../../images/logos/frymaster-uhcthd-blue.png") : this.getBrandId() === 12 ? require("../../../images/logos/XpressChef_Logo_w_R.png") : require("../../../images/logos/Merco_Color.svg").default} /></div>
									<div class="selectedBrandModel">{this.state.MODEL_NAMES}<span></span></div>
									<div class="selectedSWpakageDetails">
										<h5>{this.state.FILE_NAME}</h5>
										<h5>{this.state.VERSION}</h5>
										<h6>{this.state.UPLOADED_ON ? format(this.state.UPLOADED_ON, 'MM-DD-YYYY') : ''}</h6>
									</div>
								</div>
							</div>
							<div class="scheduleUpdateWrapperR">
								<div class="scheduleUpdateOption" onChange={this.setscheduleUpdate.bind(this)}>
									<div class="customRadioBtn">
										<input id="UpdateNow" checked={this.state.UpdateNow} type="radio" name="ScheduleUpdateOption" value="UpdateNow" />
										<label for="UpdateNow"><FormattedMessage id="KC0666"/></label>
									</div>
									<div class="customRadioBtn">
										<input id="ScheduleUpdate" checked={this.state.ScheduleUpdate} type="radio" name="ScheduleUpdateOption" value="ScheduleUpdate" />
										<label for="ScheduleUpdate"><FormattedMessage id="KC0665"/></label>
									</div>
								</div>
								<div id="Schedulediv" class="scheduleUpdateForm displaynone">
									<ul>
										<li>
											<label><FormattedMessage id="KC0749"/></label>
											<div class="input-group">
												<DatePicker locale={datePickerLocale} id="ScheduleDate" onKeyDown={(e) => this.ResetDatepicker(e)} autoComplete="off" placeholderText={formatMessage({ id: 'KC0835' })} showMonthDropdown showYearDropdown dropdownMode="select" selected={this.state.startDate} onChange={this.handleChange} minDate={new Date()} maxDate={this.state.maxdate} />
											</div>
										</li>
										<li>
											<label><FormattedMessage id="KC0750"/></label>
											<div class="input-group" id="timeSchedulePanel" onChange={(e) => this.settimeselectonmenu(e)}>
												<input type="text" autoComplete="off" placeholder={formatMessage({ id: 'KC0836' })} name="selectTime" id="selectTime" />
												<ul class="dropdownTimeOptions" >


													{thirty_Mnts_Interval.map((value) =>
														<li><a href="javascript:void(0)" data-value={value}><TimePeriodFormatter text={value}/></a></li>
													)}
												</ul>
											</div>

										</li>
									</ul>
								</div>

							</div>

						</div>

					</div>

				</div>

				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<UserManagementHeader headerName="Software Update" headerNameStringID="KC0832" activeClass="softwareNav"/>
			</div>
		)
	}
};

export default injectIntl(softwareScheduleUpdate);

