// Import Statements
import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import { injectIntl, useIntl } from 'react-intl';
import { TabWidget } from '@kcui/react/TabWidget';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import http from '../../service/httpService';
import ErrorMoal from '../modal/error-model';
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import { updateDimensions } from "../../utils/csvExUnitRedirect";
import { getUnit, getConfigFile } from '../../actions/dashboardRenderer/dashboardRenderer';
import { searchVisible, searchText, clearSearchText } from '../../actions/advanceSearch/advanceSearchAction';
import { ComponentMapping, menuMapping, dataMapping, action as Action, getFormattedDateFilterValue, dashboardRendererImageConstant, getUnitCurrentTimeFormatted, getTrendLabel, getUnitCurrentDate, dashboardRenderErrorTypeImages, getHelpLinkUrl, liveDiagnosticSoftwareVersion, offlinePopupTimeFormat, liveViewModeDeviceInfoWebSocketURL, liveViewModeWebSocketURL, liveViewDuration, liveDiagnosticAction, liveDiagnosticBrandConfig } from '../../utils/dashboardRenderer';
import { compileConfiguration } from './configuration-compiler';
import clientFunctions from './client-function/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardRendererCustom from './dashboardRendererCustom';
import { customConfiguration } from './custom-configurations';
import authService from '../../service/authService';
import OfflinePopup from './offlinePopup';
import { setUnitConnectionStatus } from '../../actions/liveDiagnostic/liveDiagnosticAction';
import {setLiveViewModeStatus, establishLiveViewConnection, clearLiveViewModeData} from '../../actions/liveViewMode/liveViewModeAction';
import { BrowserRouter } from 'react-router-dom';

var moment = require('moment');

var tabMetaData = { parsedConfiguration: {}, rawConfiguration: {}, scope: {} };
var widgetMetaData = [];
var rootScope = {};

export function invokePostMethod(url, dataObj) {
    return new Promise((res, rej) => {
        http.post(url, dataObj).then((response) => {
            res(response);
        }).catch((err) => {
            rej(err);
        });
    })
}


// Dashboard renderer component
function DashboardRenderer(props) {
    // Props
    const { match } = props;
    const { serialNo, menuName, tabName } = match.params;
    // State Initialization
    const [loaderIconVisible, setLoading] = React.useState(true);
    const [error_modal, setErrorModal] = React.useState({ open: false, message: '' });
    const [currentConfiguration, setCurrentConfiguration] = React.useState(null);
    const [tabRendererData, setTabRendererData] = React.useState({ properties: {} })
    const [widgetRendererData, setWidgetRendererData] = React.useState([])
    const [currentTab, setCurrentTab] = React.useState('');
    const [offlinePopupVisible, setOfflinePopupVisible] = React.useState(false);
    const [timeFormated, setLastConnectionTime] = React.useState('-');
    const [liveViewModeEnabled, setLiveViewModeEnabled] = useState(false)
    const [liveMonitoringEnabled, setLiveMonitoringEnabled] = useState(false)
    const [updateSessionExpiry,setUpdateSessionExpiry] = useState(false)
    const intl = useIntl();


    let unitCurrentTimeInterval;
    let unitCurrentTimeClockSync;

    // handle if undefine
    function Version(s) {
        this.arr = s?.split('.')?.map(Number) || [];
    }
    Version.prototype.compareTo = function (v) {
        for (var i = 0; ; i++) {
            if (i >= v.arr.length) return i >= this.arr.length ? true : true;
            if (i >= this.arr.length) return -1;
            var diff = this.arr[i] - v.arr[i]
            if (diff) return diff > 0 ? true : false;
        }
    }


    // Redux subscriptions
    const dispatch = useDispatch();
    const currentUnit = useSelector(state => state.entityReducer.currentUnit);
    const currentLocation = useSelector(state => state.entityReducer.currentLocation);
    const configuration = useSelector(state => state.dashboardRenderer.dashboardConfiguration);
    const searchData = useSelector(state => state.advanceSearch.searchData);
    const unitStatus = useSelector(state => state.liveDiagnostic.unitConnectionStatus);
    const liveViewState = useSelector(state => state.liveViewMode.liveViewConnectionStatus);
    const liveViewConnectionStatus = useSelector(state => state.liveViewMode.liveViewConnectionStatus);
    const liveViewData = useSelector(state => state.liveViewMode.liveViewData);

    const mounted = useRef();
    const websocketSession = useRef(false);
    const sessionExpired = useRef(false);
    const widgetsLive = useRef([]);
    const previousWidgetData = useRef([]);
    const liveViewWebSocketSession = useRef(false)
    const menuNameRef = useRef(menuName)

    // General Loading for this component
    React.useEffect(() => {
        // alert(offlinePopupVisible)
        window.scrollTo(0, 0);
        updateDimensions($);
        menuNameRef.current = menuName
        if (!currentUnit || !Object.keys(currentUnit).length) {
            setLoading(true);
            dispatch(getUnit(serialNo)).then((res) => {
                // setLoading(false);
            }).catch((err) => {
                // setLoading(false);
                setErrorModal({ open: true, message: err.message || "Something went wrong" })
            })
        } else {
            setLoading(false);
        }
        init();
        clearSearch();
        previousWidgetData.current = []
        return () => {
            clearInterval(unitCurrentTimeInterval);
            clearTimeout(unitCurrentTimeClockSync);
            for (let index = 0; index < widgetMetaData.length; index++) {
                const widget = widgetMetaData[index];
                if (widget.refreshIntervalInstance) {
                    clearInterval(widget.refreshIntervalInstance)
                }
                if (widget.refreshClockSyncInstance) {
                    clearInterval(widget.refreshClockSyncInstance)
                }
            }
        };
    }, [menuName, tabName]);

    React.useEffect(() => {
        if (menuName === "servicedashboard" && tabName !== 'livediagnostic') {
            if (!mounted.current) {
                dispatch(searchVisible(true))
                mounted.current = true;
            } else {
                // do componentDidUpdate logic
                dispatch(searchVisible(true))
            }
            rootScope.globalSearch = {};
            rootScope.isGlobalSearchEmpty = true;
            if (searchData && Object.keys(searchData).length && Object.values(searchData).filter(Boolean).length) {
                rootScope.globalSearch = searchData;
                rootScope.isGlobalSearchEmpty = false;
                handleGlobalSearch();
            } else if (searchData && Object.keys(searchData).length === 1 && !Object.values(searchData).filter(Boolean).length) {
                rootScope.globalSearch = {};
                rootScope.isGlobalSearchEmpty = true;
                handleGlobalSearch();
            }
        } else {
            rootScope.globalSearch = {};
            rootScope.isGlobalSearchEmpty = true;
            dispatch(searchVisible(false))
        }
    }, [menuName, searchData])

    // Trigger configuration if not present
    React.useEffect(() => {
        if (configuration[currentUnit.MODEL_FAMILY_NAME]) {
            setCurrentConfiguration(JSON.parse(JSON.stringify(configuration[currentUnit.MODEL_FAMILY_NAME].configuration)));
        } else if (configuration[currentUnit.MODEL_NAME]) {
            setCurrentConfiguration(JSON.parse(JSON.stringify(configuration[currentUnit.MODEL_NAME].configuration)));
        } else if (configuration[currentUnit.BRAND_NAME]) {
            setCurrentConfiguration(JSON.parse(JSON.stringify(configuration[currentUnit.BRAND_NAME].configuration)));
        } else {
            setLoading(true);
            dispatch(getConfigFile(currentUnit)).then((res) => {
                if (res?.data?.configuration) {
                    setCurrentConfiguration(JSON.parse(JSON.stringify(res.data.configuration.configuration)));
                    setLoading(false);
                }
            }).catch((err) => {
                setLoading(false);
                setErrorModal({ open: true, message: err.message || "Something went wrong" })
            })
        }
    }, [currentUnit])

    // Trigger to create the rootScope, renderer part 
    React.useEffect(() => {
        init();
    }, [currentUnit, currentConfiguration])

    const getUnitVersionAndConnectivityDetails = async () => {
        try {
            setLoading(true);
            const softwareVersionConfig = customConfiguration['unit-version-connectivity'];
            const id = softwareVersionConfig.id;
            const url = softwareVersionConfig.url;
            const softwareVersionCompilerData = {
                _rootScope: {
                    unitDetails: {
                        UNITID: currentUnit.UNITID,
                        CLOUD_UNIT_NAME: currentUnit.CLOUD_UNIT_NAME
                    }
                }
            }
            // Get Compiled Configuration
            const compiledConfiguration = await compileConfiguration(softwareVersionConfig, softwareVersionCompilerData);

            // Call Common Widget API
            const softwareVersionResponse = await invokePostMethod(url, { id: id, configuration: compiledConfiguration.configuration });
            setLoading(false);
            return softwareVersionResponse;
        } catch (error) {
            setLoading(false);
            return null;
        }
    }

    const clearSearch = () => {
        dispatch(searchText({}));
        rootScope.globalSearch = {};
        rootScope.isGlobalSearchEmpty = true;
        if ($('.cross-button').length) {
            $('.cross-button').trigger("click");
        }
        const buttonCloseSelector = $('.btn-close');
        for (let index = 0; index < buttonCloseSelector.length; index++) {
            $('.btn-close').trigger('click');
        }
        if ($('.input-group-prepend').find('a.active').length !== 0) {
            $('.input-group-prepend').trigger('click');
        }
    }

    const checkLiveViewMode = async (currentConfiguration) => {
        const tabNameValue = tabName && tabName !== "" ? tabName : currentConfiguration?.menu[dataMapping[menuName]]?.tab?.properties?.options[0]?.value;
        const widgetsForTab = currentConfiguration?.menu[dataMapping[menuName]]?.tab?.body[tabNameValue]?.widgets;
        let isLiveViewMode = false;
        for (let index = 0; index < widgetsForTab.length; index++) {
            const element = widgetsForTab[index];
            if (element?.data?.refresh?.liveViewData === true) {
                isLiveViewMode = true;
                break;
            }
        }
        return isLiveViewMode
    }

    const getLiveViewModeEnabledWidget = async (currentConfiguration) => {
        const tabNameValue = tabName && tabName !== "" ? tabName : currentConfiguration?.menu[dataMapping[menuName]]?.tab?.properties?.options[0]?.value;
        const widgetsForTab = await currentConfiguration?.menu[dataMapping[menuName]]?.tab?.body[tabNameValue]?.widgets;
        let widgetList = [];
        for (let index = 0; index < widgetsForTab.length; index++) {
            const element = widgetsForTab[index];
            if (element?.data?.refresh?.liveViewData === true) {
                widgetList.push(element.id)
            }
        }
        return widgetList
    }

    const handleLiveViewStreamData = async (currentConfiguration)=>{
        const tabNameValue = tabName && tabName !== "" ? tabName : currentConfiguration?.menu[dataMapping[menuNameRef.current]]?.tab?.properties?.options[menuNameRef.current === "unitdashboard" ? 0 : 1]?.value;
        const widgetsForTab = await currentConfiguration?.menu[dataMapping[menuNameRef.current]]?.tab?.body[tabNameValue]?.widgets;
        for (let index = 0; index < widgetsForTab.length; index++) {
            const element = widgetsForTab[index];
            if (element?.data?.refresh?.liveViewData === true) {
                await handleAction({ type: Action.loadWigetData, value: { widgetId: element.id } })
            }
        }
    }

    const init = async () => {
        if (currentUnit && Object.keys(currentUnit).length && currentConfiguration && Object.keys(currentConfiguration).length) {
            setTabRendererData({ properties: {} });
            let { isLiveMonitoringEnabled, showOfflinePopup } = await handleLiveMonitoring();
            const isLiveMode = await checkLiveViewMode(currentConfiguration);
            const liveViewEnabledWidget = await getLiveViewModeEnabledWidget(currentConfiguration)
            setLiveViewModeEnabled(isLiveMode)
            const data =[...widgetsLive.current ,...liveViewEnabledWidget]
            widgetsLive.current = [... new Set(data)]
            createRootScope(isLiveMonitoringEnabled);
            initDateTimeCalculation();
            // Software version should be above 6.0
            if (!isLiveMonitoringEnabled && isLiveMode && !websocketSession.current) {
                deviceConnectivityWebSocketConnection(true);
            }
            await createTabData(currentConfiguration);
            await createWidgetData(currentConfiguration);
            setOfflinePopupVisible(showOfflinePopup);
            getWidgetData();
            initWidgetRefresh();
        }
    };

    React.useEffect(() => {
        if (unitStatus === "Online" && !liveMonitoringEnabled && liveViewModeEnabled && !liveViewWebSocketSession.current) {
            liveSessionWebSocketConnection(true);
        }
    }, [unitStatus, liveMonitoringEnabled, liveViewModeEnabled])


    React.useEffect(()=>{
        if(websocketSession.current && sessionExpired.current && (!previousWidgetData.current.length || previousWidgetData.current[0]?.id !== widgetRendererData[0]?.id)){
            setWidgetRendererData((prev)=>{
                const newData = prev.map((item=>{
                    if(widgetsLive.current.includes(item.id)){
                        return {...item, properties:{...item.properties, showHintMessage:true} }
                    }
                    return item
                }))
                return newData
            })
            previousWidgetData.current = widgetRendererData
        }
    },[widgetRendererData, sessionExpired.current, updateSessionExpiry])

    React.useEffect(() => {
        if (!liveViewState){
            return
        }
        if(liveViewState?.state === "disable" ||  Object.keys(liveViewState).length === 0 || liveViewState?.disableRequestAt){ 
            dispatch(establishLiveViewConnection(liveDiagnosticAction.Enable, liveViewDuration, currentUnit));
        }
        else if (liveViewState?.state === "enable" && liveViewState?.enableStatusAt) {
            let duration = moment.utc().diff(moment(liveViewState?.enableStatusAt),'seconds')
            if(duration > liveViewDuration){
            dispatch(establishLiveViewConnection(liveDiagnosticAction.Enable, liveViewDuration, currentUnit));
            }      
        }
    }, [liveViewState, liveViewWebSocketSession.current])

    React.useEffect(() =>{
        return ()=>{
            dispatch(clearLiveViewModeData())
            dispatch(establishLiveViewConnection(liveDiagnosticAction.Disable, liveViewDuration, currentUnit));
            window.deviceWebsocketConnection?.close()
            window.liveWebsocketConnection?.close()
        }      
    },[])

    async function handleLiveMonitoring() {
        const checkAlreadyDone = localStorage.getItem('liveMonitoringTracker');
        let showOfflinePopup = menuName === "unitdashboard" ? localStorage.getItem('showOfflinePopup') ? localStorage.getItem('showOfflinePopup') : true : false;
        if (showOfflinePopup) {
            let getOfflinePopupDisabledUnits = localStorage.getItem('offlineDisabledUnits') ? JSON.parse(localStorage.getItem('offlineDisabledUnits')) : [];
            if (getOfflinePopupDisabledUnits.includes(currentUnit?.UNITID)) {
                showOfflinePopup = false;
                localStorage.setItem("showOfflinePopup", false)
            }
            else {
                localStorage.setItem("showOfflinePopup", true)
            }
        }
        let unitData = {};
        let versionNumber = localStorage.getItem('softwareVersionNumber') || '0.0.0';

        if (checkAlreadyDone === 'false' || !checkAlreadyDone) {
            unitData = await getUnitVersionAndConnectivityDetails();
            if (unitData?.data?.data?.data?.unit?.shadow?.CONNECT_STATE === "Online") {
                showOfflinePopup = false;
            } else {
                const dataRes = unitData?.data?.data?.data?.connectivityHistory || [];
                if (dataRes?.length && dataRes[0].lastDisconnectAt) {
                    setLastConnectionTime(moment(new Date(dataRes[0].lastDisconnectAt).toString()).tz(currentUnit.TimeZone_Standard).format(offlinePopupTimeFormat));
                }
            }

            let config = liveDiagnosticBrandConfig[currentUnit.BRAND_NAME];
            let iotVersion = await getVersion(config, unitData);
            console.log("iotVersion--->", iotVersion);
            localStorage.setItem('softwareVersionNumber',iotVersion);
            versionNumber = iotVersion;
        }
        const isLiveMonitoringEnabled = !versionNumber || versionNumber.includes('?') ? true : (new Version(versionNumber)).compareTo(new Version(liveDiagnosticSoftwareVersion[currentUnit.BRAND_NAME])) === true ? false : true;
        setLiveMonitoringEnabled(isLiveMonitoringEnabled);
        return { isLiveMonitoringEnabled, showOfflinePopup };
    }

    async function recurse(shadowData, keyPath, counter) {
        if (counter >= keyPath.length - 1) {
            return shadowData;
        } else {
            ++counter;
            return recurse(shadowData[keyPath[counter]], keyPath, counter);
        }
    }

    async function getVersion(config, unitData) {
        for (const conf of config) {
            let configKey = conf.split(".");
            let version;
            try {
                let version = await recurse(unitData, configKey, -1);
                if (version) {
                    console.log("identified versison", version);
                    return version;
                }
            } catch (err) {
                console.log("inside version check block");
                continue
            }
            console.log("version", version);
        }
        return "0.0.0"
    }

    const liveSessionWebSocketConnection = (isInitialLoad = false) => new Promise(async (resolve, reject) => {

        if ("WebSocket" in window) {
            console.log("WebSocket is supported by your Browser!");
        }
        window.liveWebsocketConnection = new WebSocket(`${liveViewModeWebSocketURL()}?cloudUnitName=${currentUnit.UNITID}_${currentUnit.BRAND_NAME}&token=${localStorage.getItem('Access-Token')}`);
        liveViewWebSocketSession.current = true
        window.liveWebsocketConnection.onopen = () => {
            setLoading(false)
            if (isInitialLoad) {
                let diagnosticSession = { "action": "GET_LIVE_VIEW_SESSION", "data": { "serialNo": currentUnit.UNITID } };
                window.liveWebsocketConnection.send(JSON.stringify(diagnosticSession));
            }
        };
        // Web Socket - Error
        window.liveWebsocketConnection.onerror = (error) => {
            console.log('WebSocket error : ', error);
            reject(error);
        };

        // Web Socket - Recive Message
        window.liveWebsocketConnection.onmessage = (evt) => {
            var received_msg = evt.data;
            const { data, type = '' } = JSON.parse(received_msg);
            if(type === "LIVE_VIEW_SESSION"){
                dispatch(setLiveViewModeStatus(data));            
            }
            if(type === "LIVE_VIEW_MODE_STATUS" && data.status === "timeout"){
                sessionExpired.current = true
                setUpdateSessionExpiry(true)
            }
            if(type === "LIVE_VIEW_DATA"){
                // for handling the stream data
                handleLiveViewStreamData(currentConfiguration, menuNameRef)
            }
        };

        // Web Socket - Close Connection
        window.liveWebsocketConnection.onclose = (results) => {
            console.log('WebSocket CLOSE CONNECTION RESULTS', results);
            resolve('CLOSE CONNECTION', results);
            if (results?.code === 1001) {
                liveSessionWebSocketConnection();
            }
        };
    })

    const deviceConnectivityWebSocketConnection = (isInitialLoad = false) => new Promise(async (resolve, reject) => {
        if ("WebSocket" in window) {
            console.log("WebSocket is supported by your Browser Live View Mode!");
        }
        window.deviceWebsocketConnection = new WebSocket(`${liveViewModeDeviceInfoWebSocketURL()}?cloudUnitName=${currentUnit.UNITID}_${currentUnit.BRAND_NAME}&token=${localStorage.getItem('Access-Token')}`);
        websocketSession.current = true
        window.deviceWebsocketConnection.onopen = () => {
            setLoading(false)
            if (isInitialLoad) {
                // Web Socket is connected, send data using send()
                let deviceConnectivityStatus = { "action": "GET_CONNECTIVITY_STATUS", "data": { "serialNo": currentUnit.UNITID } };
                window.deviceWebsocketConnection.send(JSON.stringify(deviceConnectivityStatus));
            }
        };
        // Web Socket - Error
        window.deviceWebsocketConnection.onerror = (error) => {
            console.log('WebSocket error : ', error);
            reject(error);
        };

        // Web Socket - Recive Message
        window.deviceWebsocketConnection.onmessage = (evt) => {
            var received_msg = evt.data;
            const { data, type = '' } = JSON.parse(received_msg);
            if (type === "CONNECTIVITY_STATUS") {
                dispatch(setUnitConnectionStatus(data?.state));
            }
        };

        // Web Socket - Close Connection
        window.deviceWebsocketConnection.onclose = (results) => {
            console.log('WebSocket CLOSE CONNECTION RESULTS', results);
            resolve('CLOSE CONNECTION', results);
            if (results?.code === 1001) {
                deviceConnectivityWebSocketConnection();
            }
        };
    })

     window.addEventListener('beforeunload', function () {
        window.deviceWebsocketConnection?.close()
        window.liveWebsocketConnection?.close()
        // dispatch(establishLiveViewConnection(liveDiagnosticAction.Disable, liveViewDuration, currentUnit, "Beacon"));   
    });

    // Rootscope creation
    const createRootScope = (isLiveMonitoringEnabled) => {
        const currentDate = moment(new Date()).toDate();
        const dayStart = moment(currentDate).startOf('day').toDate();
        const monthStart = moment().startOf('month').toDate();
        const weekStart = moment(new Date()).startOf('week').add('days', 1).toDate();
        const quarterStart = moment().startOf('quarter').toDate();
        const yearStart = moment().startOf('year').toDate();
        let unitDetails = currentUnit;
        let isLiveMonitoringTab = false;
        let liveMonitoringFeature = authService.getFeature("/liveMonitoring");
        if (liveMonitoringFeature && (liveMonitoringFeature.is_editable === 1 || liveMonitoringFeature.is_view_only === 1)) {
            isLiveMonitoringTab = true
        }
        if (unitDetails && unitDetails.dynastyAttributes && typeof unitDetails.dynastyAttributes === "string") {
            unitDetails.dynastyAttributes = JSON.parse(unitDetails.dynastyAttributes)
        }
        localStorage.setItem('liveMonitoringTracker', !isLiveMonitoringEnabled && isLiveMonitoringTab)
        let rootObj = {
            unitDetails,
            currentLocation,
            tabName,
            isLiveMonitoringTab,
            isLiveMonitoringEnabled,
            isDataStandardized: !isLiveMonitoringEnabled,
            dateFilter: {
                min: moment(currentDate).subtract(6, 'month').subtract(1, 'days').toDate(),
                max: currentDate,
                dayStart,
                monthStart,
                currentDate,
                weekStart,
                quarterStart,
                yearStart
            },
            imageConstant: dashboardRendererImageConstant,
            errorTypeImages: dashboardRenderErrorTypeImages,
            offlinePopupTimeFormat,
            helpLinkUrl: getHelpLinkUrl(),
            translateText: (id, values = {}) => { return intl.formatMessage({ id }, values) }
        };
        rootScope = { ...rootScope, ...rootObj };
        return rootScope;
    }

    const calculateDateTime = (rootScope) => {
        rootScope.unitCurrentTimeFormatted = getUnitCurrentTimeFormatted(rootScope.unitDetails.TimeZone_Standard);
        rootScope.unitCurrentDate = getUnitCurrentDate(rootScope.unitDetails.TimeZone_Standard);
        rootScope.currentLocalDate = new Date();
        rootScope.currentUTCDate = moment.utc();
    }


    const initDateTimeCalculation = () => {
        if (!rootScope.unitCurrentDate) {
            const date = new Date();
            calculateDateTime(rootScope);
            unitCurrentTimeClockSync = setTimeout(function () {
                unitCurrentTimeInterval = setInterval(() => {
                    calculateDateTime(rootScope);
                }, 60000);
                calculateDateTime(rootScope);
            }, (60 - date.getSeconds()) * 1000);
        }
    }

    const handleGlobalSearch = () => {
        for (let index = 0; index < widgetMetaData.length; index++) {
            const widget = widgetMetaData[index];
            if (widget.rawConfiguration.globalSearch === true) {
                handleAction({ type: Action.loadWigetData, value: { widgetId: widget.parsedConfiguration.id } })
            }
        }
    }

    const setWidgetLoadingValue = (ID, loadingValue) => {
        setWidgetRendererData((prevData) => {
            const newData = prevData.map((item) => {
                if (item.id === ID) {
                    return { ...item, properties: { ...item.properties, loader: loadingValue } };
                }
                return item;
            });
            return newData;
        });
    }

    const getWidgetData = () => {
        for (let index = 0; index < widgetMetaData.length; index++) {
            const widget = widgetMetaData[index];
            handleAction({ type: Action.loadWigetData, value: { widgetId: widget.parsedConfiguration.id } })
        }
    }

    const initWidgetRefresh = () => {
        for (let index = 0; index < widgetMetaData.length; index++) {
            let widget = widgetMetaData[index];
            if (widget.rawConfiguration.data?.refresh?.interval) {
                const date = new Date();
                const interval = widget.rawConfiguration.data.refresh.interval;
                const clockSync = widget.rawConfiguration.data.refresh.clockSync;
                if (clockSync) {
                    widget.refreshClockSyncInstance = setTimeout(function () {
                        widget.refreshIntervalInstance = setInterval(() => {
                            handleAction({ type: Action.loadWigetData, value: { widgetId: widget.parsedConfiguration.id } })
                        }, interval * 1000);
                        handleAction({ type: Action.loadWigetData, value: { widgetId: widget.parsedConfiguration.id } })
                    }, (60 - date.getSeconds()) * 1000);
                } else {
                    widget.refreshIntervalInstance = setInterval(() => {
                        handleAction({ type: Action.loadWigetData, value: { widgetId: widget.parsedConfiguration.id } })
                    }, interval * 1000);
                }
            }
        }
    }

    // Action handler function
    const _loadWidgetData = async (action) => {
        const widgetId = action.value.widgetId;
        const widget = widgetMetaData.find((i) => i.parsedConfiguration.id === widgetId);
        const data = widget?.rawConfiguration.data;
        const parsedData = widget?.parsedConfiguration.data;
        const ID = widget?.parsedConfiguration.id;

        // only if data config is available.
        if (data) {
            let requestBody;
            // execute transform request
            if (data.transformRequest) {
                requestBody = await data.transformRequest(
                    {
                        _rootScope: rootScope,
                        _widgetScope: widget?.scope,
                        _clientFunction: clientFunctions,
                        _clientResponse: widget?.clientResponse,
                        _parsedConfiguration: widget?.parsedConfiguration
                    }
                );
            }
            // invoke api
            if (data?.api) {
                // parse api configuration
                const parsedApiConfig = await compileConfiguration(data.api, {
                    _rootScope: rootScope,
                    _widgetScope: widget?.scope,
                    _clientResponse: widget?.clientResponse
                }, { executeFunction: false });

                const apiUrl = parsedApiConfig?.url || currentConfiguration?.widgetAPI?.url
                if (!requestBody) {
                    parsedApiConfig.body.execute = parsedApiConfig.body?.execute?.filter((item) => item.hasOwnProperty("if") ? item.if : true)
                    requestBody = { id: ID, configuration: parsedApiConfig.body };
                }

                // Display widget loading
                setWidgetLoadingValue(ID, true);
                // invoke api call
                invokePostMethod(apiUrl, requestBody).then(async (response) => {
                    let responseResult = response.data;
                    // execute transform response
                    if (data.transformResponse) {
                        responseResult = await parsedData.transformResponse(
                            {
                                _rootScope: rootScope,
                                _widgetScope: widget?.scope,
                                _clientFunction: clientFunctions,
                                _clientResponse: responseResult,
                            }
                        );
                    }
                    // compile map to props
                    const mapPropsValue = await compileConfiguration(widget?.rawConfiguration.data.mapToProperties, {
                        _rootScope: rootScope,
                        _widgetScope: widget?.scope,
                        _clientResponse: responseResult,
                    }, { executeFunction: false });
                    // Update MetaData
                    const metaUpdateIndex = widgetMetaData.findIndex((i) => i.parsedConfiguration.id === ID);
                    widgetMetaData[metaUpdateIndex].clientResponse = responseResult;
                    // Update State
                    setWidgetRendererData((prevData) => {
                        const newData = prevData.map((item) => {
                            if (item.id === ID) {
                                return { ...item, properties: { ...item.properties, ...mapPropsValue, loader: false } };
                            }
                            return item;
                        });
                        return newData;
                    });
                }).catch((err) => {
                    setWidgetLoadingValue(ID, false);
                })
            } else if (data?.refresh) {
                // compile and run mapToProperties when refresh configuration available
                const mapPropsValue = await compileConfiguration(widget?.rawConfiguration.data.mapToProperties, {
                    _rootScope: rootScope,
                    _widgetScope: widget?.scope
                }, { executeFunction: false });
                // Update State
                setWidgetRendererData((prevData) => {
                    const newData = prevData.map((item) => {
                        if (item.id === ID) {
                            return { ...item, properties: { ...item.properties, ...mapPropsValue } };
                        }
                        return item;
                    });
                    return newData;
                });
            }
        }
    }

    // Action handler function
    const _setWidgetScope = (action) => {
        const widgetId = action.value.widgetId;
        const widget = widgetMetaData.find((i) => i.parsedConfiguration.id === widgetId);
        widget.scope = { ...widget.scope, ...action.value.widgetScope };
    }

    // Action handler function
    const _setWidgetDateFilter = (action) => {
        for (let index = 0; index < widgetMetaData.length; index++) {
            const widget = widgetMetaData[index];
            if (widget.parsedConfiguration.properties.dateFilter) {
                if (widget.parsedConfiguration.properties.dateFilterOptions.find((i) => i.value === action.value.dateFilterValue.value)) {
                    const formattedDateFilterValue = getFormattedDateFilterValue(action.value.dateFilterValue);
                    widget.scope = { ...widget.scope, dateFilterValue: action.value.dateFilterValue, ...formattedDateFilterValue, trendLabel: getTrendLabel(action.value.dateFilterValue.value) };
                    if (action.value.dateFilterValue.fromDate && action.value.dateFilterValue.toDate) {
                        handleAction({ type: Action.loadWigetData, value: { widgetId: widget.parsedConfiguration.id } })
                    }
                }
            }
        }
        setWidgetRendererData((prevData) => {
            const newData = prevData.map((item) => {
                if (item.properties.dateFilter) {
                    if (item.properties.dateFilterOptions.find((i) => i.value === action.value.dateFilterValue.value)) {
                        return { ...item, properties: { ...item.properties, dateFilterValue: action.value.dateFilterValue } };
                    }
                    return item;
                }
                return item;
            });
            return newData;
        });
    }

    const handleActions = (actions) => {
        for (let index = 0; index < actions.length; index++) {
            const action = actions[index];
            handleAction(action)
        }
    }

    const handleAction = async (action) => {
        switch (action.type) {
            case Action.loadWigetData:
                await _loadWidgetData(action);
                break;
            case Action.setWidgetScope:
                _setWidgetScope(action);
                break;
            case Action.setWidgetDateFilter:
                _setWidgetDateFilter(action);
                break;
            default:
                break;
        }
    }

    // Set the tab property using parsedconfig and expressions from jsonata 
    const createTabData = async (rawConfiguration) => {
        const tabNameValue = tabName && tabName !== "" ? tabName : rawConfiguration?.menu[dataMapping[menuName]]?.tab?.properties?.options[0]?.value;
        const props = rawConfiguration?.menu[dataMapping[menuName]]?.tab?.properties;

        let scope = {};
        const parsedConfig = await compileConfiguration(props, {
            _rootScope: rootScope,
            _widgetScope: scope,
            _clientFunction: clientFunctions
        }, { executeFunction: false });
        if (parsedConfig.widgetScope) {
            scope = { ...scope, ...parsedConfig.widgetScope }
        }
        if (parsedConfig.dateFilter) {
            scope.dateFilterValue = parsedConfig.dateFilterValue;
            const formattedDateFilterValue = getFormattedDateFilterValue(parsedConfig.dateFilterValue)
            scope = { ...scope, ...formattedDateFilterValue, trendLabel: getTrendLabel(parsedConfig.dateFilterValue.value) };
            parsedConfig.onTriggerDateFilterChange = async (event) => {
                const formattedDateFilterValue = getFormattedDateFilterValue(event.data)
                tabMetaData.scope = { ...tabMetaData.scope, ...formattedDateFilterValue, trendLabel: getTrendLabel(event.data.value) }
                await handleAction({ type: Action.setWidgetDateFilter, value: { dateFilterValue: event.data } });
            }
        }
        tabMetaData = {
            rawConfiguration: props || {},
            parsedConfiguration: parsedConfig || {},
            scope
        }
        const tabData = {
            properties: parsedConfig || {},
        }
        setCurrentTab(tabNameValue);
        setTabRendererData(tabData);
        return tabData;
    }

    // This is the wrapper function for functions defined in configuration
    const configurationFunctionFactory = (fn, widgetId) => {
        return async (event) => {
            const widget = widgetMetaData.find((i) => i.parsedConfiguration.id === widgetId);
            const result = await fn({
                _rootScope: rootScope,
                _widgetScope: widget.scope,
                _clientResponse: widget.clientResponse,
                _clientFunction: clientFunctions,
                widget,
                event,
                props,
                setWidgetLoadingValue
            });
            if (result?.length) {
                handleActions(result);
            }
        }
    }

    const handlePaginationChange = ({ event, widget }) => {
        return [
            { type: Action.setWidgetScope, value: { widgetId: widget.rawConfiguration.id, widgetScope: { pagination: event.data } } },
            { type: Action.loadWigetData, value: { widgetId: widget.rawConfiguration.id } }
        ]
    }

    // Set the tab property using parsedconfig and expressions from jsonata 
    const createWidgetData = async (rawConfiguration) => {
        const tabNameValue = tabName && tabName !== "" ? tabName : rawConfiguration?.menu[dataMapping[menuName]]?.tab?.properties?.options[0]?.value;
        const rawWidgetConfig = rawConfiguration?.menu[dataMapping[menuName]]?.tab?.body[tabNameValue]?.widgets;

        let widgetObj = [];
        let widgetMetaDataObj = [];

        if (rawWidgetConfig?.length) {
            for (let index = 0; index < rawWidgetConfig.length; index++) {
                const widget = rawWidgetConfig[index];
                let scope = {};
                let parsedConfig = await compileConfiguration(widget, {
                    _rootScope: rootScope,
                    _widgetScope: scope,
                    _clientFunction: clientFunctions
                }, { executeFunction: false });
                if (parsedConfig.widgetScope) {
                    scope = { ...scope, ...parsedConfig.widgetScope }
                }
                if (parsedConfig.properties?.dateFilter) {
                    scope.dateFilterValue = parsedConfig.properties.dateFilterValue;
                    const formattedDateFilterValue = getFormattedDateFilterValue(parsedConfig.properties.dateFilterValue)
                    scope = { ...scope, ...formattedDateFilterValue, trendLabel: getTrendLabel(parsedConfig.properties.dateFilterValue.value) };
                }
                const widgetMetaValue = {
                    rawConfiguration: widget,
                    parsedConfiguration: parsedConfig,
                    scope,
                    clientResponse: {}
                }
                for (const key in parsedConfig.properties) {
                    if (typeof parsedConfig.properties[key] === "function") {
                        parsedConfig.properties[key] = configurationFunctionFactory(parsedConfig.properties[key], widget.id)
                    }
                }
                if (parsedConfig.properties.pagination && !parsedConfig.properties.onTriggerPaginationChange) {
                    parsedConfig.properties.onTriggerPaginationChange = configurationFunctionFactory(handlePaginationChange, widget.id);
                }
                if (parsedConfig.properties.dateFilter) {
                    parsedConfig.properties.onTriggerDateFilterChange = async (event) => {
                        if (event.data.fromDate && event.data.toDate) {
                            const formattedDateFilterValue = getFormattedDateFilterValue(event.data)
                            await handleAction({ type: Action.setWidgetScope, value: { widgetId: parsedConfig.id, widgetScope: { dateFilterValue: event.data, ...formattedDateFilterValue, trendLabel: getTrendLabel(event.data.value) } } });
                            setWidgetRendererData((prevData) => {
                                const newData = prevData.map((item) => {
                                    if (item.id === parsedConfig.id) {
                                        return { ...item, properties: { ...item.properties, dateFilterValue: event.data } };
                                    }
                                    return item;
                                });
                                return newData;
                            });
                            await handleAction({ type: Action.loadWigetData, value: { widgetId: parsedConfig.id } })
                        }
                    }
                }
                const widgetValue = {
                    id: parsedConfig.id,
                    span: parsedConfig.span,
                    type: parsedConfig.type,
                    widget: ComponentMapping(parsedConfig),
                    properties: parsedConfig.properties,
                };
                widgetObj.push(widgetValue);
                widgetMetaDataObj.push(widgetMetaValue);
            }
        }
        widgetMetaData = widgetMetaDataObj
        // previousWidgetData.current = widgetRendererData
        setWidgetRendererData(widgetObj);
        return widgetObj;
    }

    const closeModel = () => {
        setErrorModal({ open: false })
    }

    return (
        <React.Fragment>
            <div id="wrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <GlobalHeaderLayout activeClass={menuMapping[menuName]} isDashboardRenderer={true} />
                <div id="page-content-wrapper" className='dashboardRenderer'>
                    <div className="pageHeader">
                        <TabWidget
                            {...tabRendererData.properties}
                            basePath={`/_dashboard/${serialNo}/${menuName}`}
                            onTriggerChange={(e) => {
                                props.history.push(`/_dashboard/${serialNo}/${menuName}/${e.data.value}`)
                            }}>
                            <React.Fragment key={currentTab}>
                                {(_, value) => {
                                    return (
                                        <>
                                            <div key={value} className={`render-part dashboard-${value}-tab`}>
                                                {widgetRendererData?.length ? [widgetRendererData.map((widgetObj, index) => {
                                                    return (
                                                        <div key={widgetObj.id + index} id={widgetObj.id} className={`dashboard-renderer dashboard-renderer-span${widgetObj.span?.toString().replace('.', '_')}`}>
                                                            <widgetObj.widget {...widgetObj.properties} />
                                                        </div>
                                                    )
                                                }), <DashboardRendererCustom currentUnit={currentUnit} currentLocation={currentLocation} menuName={menuName} tabName={currentTab} />] : loaderIconVisible ? null
                                                    : <div />}
                                            </div>
                                            {menuName === "unitdashboard" && offlinePopupVisible === true && !loaderIconVisible ? <OfflinePopup show={offlinePopupVisible} unitId={currentUnit.UNITID} timeFormated={timeFormated} /> : null}
                                        </>
                                    )
                                }}
                            </React.Fragment>
                        </TabWidget>
                    </div>
                </div>
            </div>
            <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => closeModel()} />
        </React.Fragment >
    )
}

function mapDispatchToProps(dispatch,) {
    return bindActionCreators({
        getUnit,
        searchVisible,
        searchText,
        clearSearchText
    }, dispatch);
}

function mapStateToProps(state,) {
    return {
        currentUnit: state.entityReducer.currentUnit,
        currentLocation: state.entityReducer.currentLocation,
        searchData: state.advanceSearch.searchData,
    }
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DashboardRenderer))