import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader'
import { brandLogo } from '../../../utils/imgContants';
import { SoftwareAvailableBrandImage, SelectedBrandImage } from '../../../utils/unitResponseBuilder';
import { getSoftwareAvailableUpdates } from '../../../actions/navigation/softwareManagement/softwareAvailableUpdatesActions'
import { getStoreInstalledItem } from '../../../actions/navigation/softwareManagement/storeInstalledItemAction'
import { TableHeader } from '../../controls/tableHeader';
import ErrorMoal from '../../modal/error-model';
import { BRAND_ID_VALUE } from '../../../utils/appConstants'
import _ from 'lodash';
import { format } from 'date-fns';
import NoRecordsFound from '../../controls/noRecordsFound';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

const brandsLogoImg = brandLogo;

class SoftwareAvailableUpdates extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			brandImage: {},
			showBP: 0
		};
		this.closeModel = this.closeModel.bind(this);
	}
	componentDidMount() {
		this.props.getSoftwareAvailableUpdates();
		document.body.style.backgroundColor = "#F2F2F2";
		this.setState({ brandImage: SelectedBrandImage(localStorage.getItem("brandName"))})
		if(localStorage.getItem("brandName") === "CONVOTHERM") this.setState({showBP: Number(localStorage.getItem("enableBakingPlan"))})
	}

	onInstallButtonClickHandler(item) {
		let message = "";
		this.props.getStoreInstalledItem(item);
		localStorage.setItem("brandName", item.BRAND);
		localStorage.setItem('modelFamilyIds', item.MODEL_FAMILY_IDS);
		if (item.BRAND === "MERRYCHEF") {
			if (item.FILE_FORMAT.toLowerCase() === "tar.gz" || item.FILE_FORMAT.toLowerCase() === "tar" || item.FILE_FORMAT.toLowerCase() === "bin") {
				let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
				localStorage.setItem("modelIdValue", item.MODEL_IDS);
				this.props.history.push({
					pathname: "/softwareScheduleUpdate",
					FILE_NAME: item.FILE_NAME,
					VERSION: item.VERSION,
					UPLOADED_ON: item.UPLOADED_ON,
					brandId: brandId,
					MODEL_IDS: item.MODEL_IDS,
					MODEL_NAMES: item.MODEL_NAMES
				});
			}
			else {
				message = "KC1543";
			}
		}
		else if (item.BRAND === "MANITOWOC ICE" || item.BRAND === "DELFIELD" || item.BRAND === "MULTIPLEX" || item.BRAND === "LINCOLN") {
			if (item.FILE_FORMAT.toLowerCase() === "tar.gz") {
				let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
				localStorage.setItem("modelIdValue", item.MODEL_IDS);
				this.props.history.push({
					pathname: "/softwareScheduleUpdate",
					FILE_NAME: item.FILE_NAME,
					VERSION: item.VERSION,
					UPLOADED_ON: item.UPLOADED_ON,
					brandId: brandId,
					MODEL_IDS: item.MODEL_IDS,
					MODEL_NAMES: item.MODEL_NAMES
				});
			}
			else {
				message = "KC1544";
			}
		}
		else if (item.BRAND === "FRYMASTER") {
			if (item.FILE_FORMAT.toLowerCase() === "zip" || item.FILE_FORMAT.toLowerCase() === "tar.gz" || item.FILE_FORMAT.toLowerCase() === "ini" || item.FILE_FORMAT.toLowerCase() === "tar") {
				let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
				localStorage.setItem("modelIdValue", item.MODEL_IDS);
				this.props.history.push({
					pathname: "/softwareScheduleUpdate",
					FILE_NAME: item.FILE_NAME,
					VERSION: item.VERSION,
					UPLOADED_ON: item.UPLOADED_ON,
					brandId: brandId,
					MODEL_IDS: item.MODEL_IDS,
					MODEL_NAMES: item.MODEL_NAMES
				});
			}
			else {
				message = "KC1738";
			}
		}
		else if (item.BRAND === "CREM") {
			if (item.FILE_FORMAT.toLowerCase() === "zip" || item.FILE_FORMAT.toLowerCase() === "dat" || item.FILE_FORMAT.toLowerCase() === "bin" || item.FILE_FORMAT.toLowerCase() === "bin.ftp" || item.FILE_FORMAT.toLowerCase() === "ftp") {
				let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
				localStorage.setItem("modelIdValue", item.MODEL_IDS);
				this.props.history.push({
					pathname: "/assignUpdateList",
					FILE_NAME: item.FILE_NAME,
					VERSION: item.VERSION,
					UPLOADED_ON: item.UPLOADED_ON,
					brandId: brandId,
					MODEL_IDS: item.MODEL_IDS,
					MODEL_NAMES: item.MODEL_NAMES
				});
			}
			else {
				message = "KC1739";
			}
		}
		else if (item.BRAND === "MERCO" || item.BRAND === "FRYMASTER-UHCTHD") {
			if (item.FILE_FORMAT.toLowerCase() === "gz" || item.FILE_FORMAT.toLowerCase() === "tar") {
				let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
				localStorage.setItem("modelIdValue", item.MODEL_IDS);
				this.props.history.push({
					pathname: "/softwareScheduleUpdate",
					FILE_NAME: item.FILE_NAME,
					VERSION: item.VERSION,
					UPLOADED_ON: item.UPLOADED_ON,
					brandId: brandId,
					MODEL_IDS: item.MODEL_IDS,
					MODEL_NAMES: item.MODEL_NAMES
				});
			}
			else {
				message = "KC1547";
			}
		}
		//Convotherm updates for Mini & maxx update for enabling .upd files 
		else if (item.BRAND === "CONVOTHERM") {
			if (item.FILE_FORMAT.toLowerCase() === "tar.gz" || item.FILE_FORMAT.toLowerCase() === "upd" || item.FILE_FORMAT.toLowerCase() === "csv") {
				let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
				localStorage.setItem("modelIdValue", item.MODEL_IDS);
				this.props.history.push({
					pathname: "/softwareScheduleUpdate",
					FILE_NAME: item.FILE_NAME,
					VERSION: item.VERSION,
					UPLOADED_ON: item.UPLOADED_ON,
					brandId: brandId,
					MODEL_IDS: item.MODEL_IDS,
					MODEL_NAMES: item.MODEL_NAMES
				});
			}
			else {
				message = "KC1740";
			}
		}
		/*Garland brand software implementation code added by kamal **/
		else if (item.BRAND === "GARLAND") {
			if (item.FILE_FORMAT.toLowerCase() === "tar.gz" || item.FILE_FORMAT.toLowerCase() === "zip" || item.FILE_FORMAT.toLowerCase() === "dat" || item.FILE_FORMAT.toLowerCase() === "tar.bz2") {
				let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
				localStorage.setItem("modelIdValue", item.MODEL_IDS);
				this.props.history.push({
					pathname: "/assignUpdateList",
					FILE_NAME: item.FILE_NAME,
					VERSION: item.VERSION,
					UPLOADED_ON: item.UPLOADED_ON,
					brandId: brandId,
					MODEL_IDS: item.MODEL_IDS,
					MODEL_NAMES: item.MODEL_NAMES
				});
			}
			else {
				message = "KC1741";
			}
		}
		else if (item.BRAND === "XPRESSCHEF") {
			if (item.FILE_FORMAT.toLowerCase() === "zip") {
				let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
				localStorage.setItem("modelIdValue", item.MODEL_IDS);
				this.props.history.push({
					pathname: "/softwareScheduleUpdate",
					FILE_NAME: item.FILE_NAME,
					VERSION: item.VERSION,
					UPLOADED_ON: item.UPLOADED_ON,
					brandId: brandId,
					MODEL_IDS: item.MODEL_IDS,
					MODEL_NAMES: item.MODEL_NAMES
				});
			}
			else {
				message = "KC2513";
			}
		}
		if (message !== "") {
			this.setState({
				error_modal: {
					open: true,
					message: message
				}
			}
			);
		}
	}

	closeModel() {
		this.setState({
			error_modal: {
				open: false
			}
		})
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	// Woopra Events
	trackEvent = (e, title = '') => {
		WoopraEvents(`${Constants.SOFTWARE}-${(title || e.target.title)}`)
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { softwareAvailableUpdatesInfo,loaderIconVisible } = this.props;
		const {  sortColumn, error_modal, brandImage, showBP } = this.state;
		let orderId = 1;

		let columns = [
			{ path: "", label: "", isNbSp: true },
			{ path: "MODEL_FAMILY_NAMES", labelStringID: "KC0649" },
			{ path: "FILE_NAME", labelStringID: "KC0832" },
			{ path: "VERSION", labelStringID: "KC0834" },
			{ path: "UPLOADED_ON", labelStringID: "KC0725" },
			{ path: "", label: "", isNbSp: true },
		];

		softwareAvailableUpdatesInfo.Software_List = _.orderBy(softwareAvailableUpdatesInfo.Software_List, [sortColumn.path], [sortColumn.order]);
		if (softwareAvailableUpdatesInfo.Software_List.length > 0) {
			softwareAvailableUpdatesInfo.Software_List = softwareAvailableUpdatesInfo.Software_List.filter(item => { if (item.BRAND === localStorage.getItem("brandName")) return item });
		}
		softwareAvailableUpdatesInfo.Software_List.forEach(item => {
			item.order_id = orderId++
		});

		return (
			<React.Fragment>

				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Fixed Header navbar --> */}
					<div id="page-content-wrapper">

						<div className="softwareUpdateWrapper">
							<div className="softwareUpdateHeader">
								<ul>
									<li>
										<Link to="#" title={formatMessage({ id: 'KC0830' })} className="active"><FormattedMessage id="KC0830"/></Link>
										{showBP ? <Link to="/bakingPlan" title={formatMessage({ id: 'KC2345' })} onClick={(e) => this.trackEvent(e, 'Baking Plan')}><FormattedMessage id="KC2345"/></Link> : ''}
										<Link to="/softwareUpdatesStatus" title={formatMessage({ id: 'KC0659' })} onClick={(e) => this.trackEvent(e, 'Update Status')}><FormattedMessage id="KC0659"/></Link>
									</li>
									<li>
										<span className="selectedBrand"><img src={brandImage.Brand_Logo} alt={brandImage.Brand_Alt} title={brandImage.Brand_Title} /></span>
									</li>
								</ul>
							</div>
							<div className="SWavlUpdtsDivDiv">
								<div className="SWavlUpdtsDivOuter">
									<div className="SWavlUpdtsDiv">
										<TableHeader
											userMgmtListScreen={true}
											sortColumn={sortColumn}
											onSort={this.onSort}
											columns={columns} />
									</div>
									
										{/* <!-- Header 01 --> */}
										{SoftwareAvailableBrandImage(softwareAvailableUpdatesInfo.Software_List).map((item) =>
											<div className="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="true">
												<div className="SWavlUpdtsDiv">
													<ul className="tBody">
														<li><button role="button" data-toggle="collapse" data-parent="#availableUpdatesAccordion"
															href={"#availableUpdatesAcc" + item.ID} aria-expanded="false" aria-controls={"availableUpdatesAcc" + item.ID} className="btn collapseExpand collapsed">&nbsp;</button></li>
														<li>{item.MODEL_FAMILY_NAMES}
															<div className="SWUpdateSM">{item.FILE_NAME}</div>
															<div className="SWUpdateSMVD"><FormattedMessage id="KC0834"/>: {item.VERSION}, <b>{item.UPLOADED_ON ? format(item.UPLOADED_ON, 'MM-DD-YYYY') : ''}</b></div></li>
														<li>{item.FILE_NAME}
															{/* Feature Update Pack */}
														</li>
														<li>{item.VERSION}</li>
														<li>{item.UPLOADED_ON ? format(item.UPLOADED_ON, 'MM-DD-YYYY') : ''}</li>
														<li><button onClick={() => this.onInstallButtonClickHandler(item)} className="btn btn-default"><FormattedMessage id="KC0561"/></button></li>
													</ul>
												</div>
												{/* <!-- Header 01 Content --> */}
												<div id={"availableUpdatesAcc" + item.ID} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"availableUpdatesAccH" + item.ID}>
													<div className="panel-body SWavlUpdtsExp">
														<ul className="tBody">
															<li></li>
															<li>
																<div className="SWavlUpdtsDivI">
																	<ul>
																		<li><b><FormattedMessage id="KC0649"/></b></li>
																		<li><b><FormattedMessage id="KC0064"/></b></li>
																	</ul>
																	{item.MODEL_DETAILS && item.MODEL_DETAILS.map((subitem) =>
																		<ul>
																			<li>{subitem.modelFamilyName}</li>
																			<li>{subitem.modelName && subitem.modelName.length > 0 ? Array.prototype.map.call(subitem.modelName, m => m).toString() : ""}</li>
																		</ul>
																	)}
																</div>
															</li>
															<li>{item.DESCRIPTION}</li>
														</ul>
													</div>
												</div> </div>)}
									
								</div>
							</div>
							<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
							{!loaderIconVisible && softwareAvailableUpdatesInfo && (softwareAvailableUpdatesInfo.Software_List === undefined || softwareAvailableUpdatesInfo.Software_List.length === 0) ?  <div className={'norecordsfoundbluetext'}>								
								<FormattedMessage id="KC0791"/>
							</div> : null}
						</div>
					</div>
				</div>
				<UserManagementHeader headerName="Software Management" headerNameStringID="KC0633" activeClass="softwareNav" />
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		softwareAvailableUpdatesInfo: state.softwareAvailableUpdatesInformation.softwareAvailableUpdatesInformation,
		storeInstalledItemInfo: state.storeInstalledItemInformation.storeInstalledItemInformation,
		loaderIconVisible: state.softwareAvailableUpdatesInformation.loaded,

	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getSoftwareAvailableUpdates, getStoreInstalledItem,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SoftwareAvailableUpdates));
