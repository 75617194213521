import $ from 'jquery';
import _ from 'lodash';
import React, { Component } from 'react';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import { FormattedMessage } from 'react-intl';
import TermsAndCondition from '../RegisterDevice/termsAndCondition';

import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';

export default class ThankyouPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            termAndCondition: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            keyValue: {},
            isPageLoad: false
        };
    }

    updateDimension = () => {
        $('.mCustomScrollbar').mCustomScrollbar();

        var windowHeight = $(window).height();

        // For term and condition height
        var termsConditionsDHeight = ((windowHeight) - 100);
        $(".termsConditionsD").css("height", termsConditionsDHeight);
    }

    async componentDidMount() {
        $('body').addClass("deviceRegtrnBg");
        this.updateDimension()
        const consumerData = await (localStorage.getItem("consumerData") ? JSON.parse(localStorage.getItem("consumerData")) : {});
        if (!consumerData || !consumerData.userDetails) {
            this.setState({
                loaderIconVisible: false,
                isPageLoad: true
            })
            return;
        }
        this.setState({
            keyValue: {
                userName: consumerData && consumerData.userDetails && consumerData.userDetails.name ? consumerData.userDetails.name : '',
                monthlyFeePerUnit: consumerData && consumerData.monthlyfees ? consumerData.monthlyfees : '',
                costPerCook: consumerData && consumerData.costPerCook ? consumerData.costPerCook : '',
                entity: consumerData && consumerData.entity ? consumerData.entity : '',
                invoiceDate: consumerData && consumerData.invoiceDate ? consumerData.invoiceDate : '',
                consumerType: consumerData && consumerData.base_country ? consumerData.base_country : '',
                phone: consumerData && consumerData.phone_number ? consumerData.phone_number : '',
                email: consumerData && consumerData.email ? consumerData.email : '',
                userDetails: consumerData && consumerData.userDetails ? consumerData.userDetails : {}
            },
            loaderIconVisible: false
        })
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            },
        })
    }

    onSuccessCloseModal = async (e) => {
        this.setState({
            success_modal: {
                open: false,
            },
        })
    }

    signUp = () => {
        this.props.history.push('/subscribe-customer')
    }


    render() {
        const { loaderIconVisible, error_modal, success_modal, termAndCondition, keyValue, isPageLoad } = this.state;
        if (isPageLoad) {
            return (<div id="page-wrapper">
                <div className="underConstruction">
                    <div className="underConstructionImg"></div>
                    <h3>You are not authorized to view this page.</h3>
                    <div className="clear"></div>
                </div>
            </div>)
        }
        return (
            <div id="adminWrapper">
                <div className="toggleTransparent"></div>
                <div className="sideNavToggleTransparent"></div>
                <div className="userToggleTransparent"></div>
                <div id="page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <a className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" href="/">&nbsp;</a>
                </nav>
                <div id="page-wrapper">
                    {/* <!-- Register Your Device Form --> */}
                    <div className="eaasWrapper">
                        <div className="thankYouEAAS">
                            <div className="thankYouInEAAS">
                                <div className="thankYouEAASCnt">
                                    <h5><FormattedMessage id='KC0896' /> {keyValue.userName}</h5>
                                    <p><img src={require('../../images/unit/MerrychefeikonE2S.svg').default} alt="Merrychef Eikon E2S" title="Merrychef Eikon E2S" /><b>Welcome to Welbilt's Equipment as a Service (EaaS) program.</b> These are the economic conditions for your subscription.</p>
                                </div>

                                <div className="registerYourDevice">
                                    <ul>
                                        <li>Monthly fee per Unit: <span> {keyValue.consumerType === "UK" ? '£' : '$'}{"65.0 per month"}</span></li>
                                        <li>Cost per Cook: 
                                            <span>{keyValue.consumerType === "UK" ? '£' : '$'}{"0.01 per cook (up to 3k)"}</span>
                                            <span>{keyValue.consumerType === "UK" ? '£' : '$'}{"0.00 per cook  (over 3k)"}</span>
                                        </li>
                                    </ul>                                    
                                    <div className="termsAndConditions">
                                        <div className="customCheckbox customCheckboxWlabel">
                                                <input id="TermsandConditions" checked={termAndCondition} onChange={() => this.setState({ termAndCondition: !this.state.termAndCondition })} name="TermsandConditions" type="checkbox" value="TermsandConditions" />
                                                <label for="TermsandConditions">&nbsp;</label>
                                        </div>
                                        <div className="termsAndC">By proceeding to Sign Up I agree to <b>Welbilt EaaS program</b> <a href="#" data-toggle="modal" data-target="#termsAndConditionsModal">Terms and Conditions</a></div>
                                    </div>
                                </div>

                            </div>
                            <button type="button" disabled={!termAndCondition} onClick={() => this.signUp()}  className="btn btn-default signUpBtn"><FormattedMessage id='KC0023' /></button>
                        </div>
                    </div>
                    {/* <!-- //Register Your Device Form --> */}
                </div >
                {/*<!-- Report Details Modal --> */}
                <div className="modal fade termsConditionsModal" data-keyboard="true" data-backdrop="static" id="termsAndConditionsModal" tabindex="-1" role="dialog" aria-labelledby="reportDetailsModalLabel" style={{ display: 'none' }} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header modal-header-danger">
                                Terms and Conditions <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                            </div>
                            <div className="modal-body">
                                <div className="termsConditionsD" style={{ height: "253px;" }}>
                                    <TermsAndCondition />
                                    <button className="btn btn-secondary termsConditionAgreeBtn" onClick={() => this.setState({ termAndCondition: !this.state.termAndCondition })} data-dismiss="modal" type="button">I Agree</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Report Details Modal -->  */}
                <ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </div >
        )
    }
};
