import { SET_LIVE_VIEW_DATA_STATUS, SET_LIVE_VIEW_DATA_DATA, CLEAR_LIVE_VIEW_SESSION_DATA } from './type';
import {  getLiveViewConnectionDetails, establishLiveViewConnectionEvent } from '../../middleware/liveViewMode/liveViewMiddleware';
import dashboardRenderer from '../../components/DashboardRenderers/dashboardRenderer';

export const setLiveViewModeStatus = (status) => {
    return dispatch => {
        dispatch({
            type: SET_LIVE_VIEW_DATA_STATUS,
            payload: status
        })
    }
}


export const setLiveViewModeData = (data) => {
    return dispatch => {
        dispatch({
            type: SET_LIVE_VIEW_DATA_DATA,
            payload: data
        })
    }
}

export const establishLiveViewConnection = (state, duration, currentUnit) => {
    return dispatch => {
        return new Promise((resolve, _) => {
            const data = {
                "modeRequested": "liveViewMode",
                state,
                duration,
                "deviceUuid": `${currentUnit.UNITID}_${currentUnit.BRAND_NAME}_${currentUnit.MODEL_NAME}`,
                "enabledBy": localStorage.getItem('user')
            };
            // if(state === "Beacon"){
            //     navigator.sendBeacon(dashboardRenderer.liveVIiewMode, data)
            // }
            // else {
                establishLiveViewConnectionEvent(data).then((res) => {
                    getLiveViewConnectionDetails(currentUnit.UNITID).then((res) => {
                        resolve(res);
                    })
                })
            // }
        })
    }
}

export const clearLiveViewModeData = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_LIVE_VIEW_SESSION_DATA,
            
        })
    } 
}