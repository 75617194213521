import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import SuccessMoal from '../modal/success-model';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import http from '../../service/httpService';
import { UserProfile } from '../../service/api';
import qs from 'query-string';
import { staticNotificationTypeData } from '../../utils/messageConfig';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import { FormattedMessage, injectIntl } from 'react-intl';

class NotificationManagementView extends Component {
	constructor(props) {
		super(props);		
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.toggleHidden = this.toggleHidden.bind(this)
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		
		this.state = {			
			isHidden: false,
			notificationData: [],
			loaderIconVisible: false,
			isTextIndeterminateSelect:false,
			isEmailIndeterminateSelect:false,
			success_modal: {
				open:false,
			  message:""
			},
			staticNotificationType: ""
		};
	}
	
	onSuccessCloseModal() {
		const { formatMessage } = this.props.intl;
		this.setState({			
			success_modal:{
				open:false,
				message: formatMessage({id: 'KC0977'})
			},
		}); 
	}

 
	handleALLSelectedItems(e, item) {	
		let isChecked = e.target.checked;
		let preferenceList = [...this.state.notificationData];
		preferenceList.forEach(p => {							
			e.target.value ==="TEXT" ? p.isTextChecked = isChecked : p.isEmailChecked = isChecked;
			p.preference_value = e.target.value ==="TEXT" ?  "TEXT" : "EMAIL";
		});
		this.setState({
			notificationData : preferenceList 
		});
	}

	toggleHidden (event) {	
		event.preventDefault();  	
		if (!this.state.isHidden) {                   
		  document.addEventListener('click', this.handleOutsideClick, false);     
		} else {
		  document.removeEventListener('click', this.handleOutsideClick, false);	
		}
		this.setState({
		  isHidden: !this.state.isHidden
		})
	  }

	  handleOutsideClick(event) {
		// ignore clicks on the component itself             
	   if(this.pop) {
		if(this.pop.contains(event.target)) {
			return;
		}    
		this.toggleHidden(event);  
	   }
	}

	getUserNotificationInformation = () => {				
		let loginUser =   JSON.parse(localStorage.getItem("loginInfo"));
		let customeremail = loginUser.username;
		this.setState({loaderIconVisible: true,})

		const values = qs.parse(this.props.location.search); 
        if (values.user !== undefined)
        {
			customeremail = values.user;		
			var decVal = Buffer.from(values.user, 'base64')
			var setEmailValue = decVal.toString();
			customeremail = setEmailValue;				
			this.setState({ queryString: values.user });
		}
		
		http({
			method: "get",
			url: `${UserProfile.getNotificationDetails}`,			
			headers: {
				'customer_id': localStorage.getItem("customerId"),
				'email': customeremail,
				'Content-Type': 'application/json'
			},data:{},

		}).then(response => {
			let preferenceData = [...response.data.data];
	 	    preferenceData.forEach(p => {
			if (p.preference_value && p.preference_value.indexOf("Text")!==-1) {
				p.isTextChecked = true
			}
			if (p.preference_value && p.preference_value.indexOf("Email")!==-1) {
				p.isEmailChecked = true
			}
			if (p.preference_name === "offlineDeviceNotify" && p.preference_value>0) {
				p.isEmailChecked = true;
				p.value=p.preference_value;
				p.preference_value="Email"; 				
			}
			});

			['EMAIL','TEXT'].map(e => {
				let checked = preferenceData.filter(c => e === "TEXT"? c.isTextChecked === true: c.isEmailChecked === true);
				this.setState({...this.getCheckBoxstate(preferenceData.length,checked.length,e) });
			})

			this.setState({ notificationData: preferenceData,loaderIconVisible: false, })
			this.setState({ staticNotificationType: staticNotificationTypeData });		
			
		}).catch(err => {
			console.log('catch exception occured');
			this.setState({loaderIconVisible: false,})
		});
	}

	getCheckBoxstate(ListCount,SelectedCount,option) {
		if(option == 'TEXT'){
			if (SelectedCount == ListCount) {
				return { isAllTEXTChkboxSelected: true, isTextIndeterminateSelect: false };
			} else if (SelectedCount == 0 && ListCount != 0) {
				return { isAllTEXTChkboxSelected: false, isTextIndeterminateSelect: false };
			} else if (ListCount >= SelectedCount) {
				return { isAllTEXTChkboxSelected: true, isTextIndeterminateSelect: true };
			}

		}else if(option == 'EMAIL'){
			if (SelectedCount == ListCount) {
				return { isAllEMAILChkboxSelected: true, isEmailIndeterminateSelect: false };
			} else if (SelectedCount == 0 && ListCount != 0) {
				return { isAllEMAILChkboxSelected: false, isEmailIndeterminateSelect: false };
			} else if (ListCount >= SelectedCount) {
				return { isAllEMAILChkboxSelected: true, isEmailIndeterminateSelect: true };
			}

		}
       
    }

	componentDidMount() {
		this.getUserNotificationInformation();	
		document.body.style.backgroundColor = "#F2F2F2";	
	}

	// Woopra Events
	trackEvent = (e) => {
		WoopraEvents(`${e.trackTitle}`);
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { notificationData, success_modal, loaderIconVisible, queryString, staticNotificationType } = this.state;			
		let  rediredtFromViewAccountpage =this.props.location.fromViewAccountpageValue
// 		let userNotificationAuthFeature = authService.getFeature("/notificationManagement");
//         let notificationLink="";
//         notificationLink="/NotificationManagementView?user=";
//         if (userNotificationAuthFeature.is_editable === 1)
//         {
// 			notificationLink="/NotificationManagement?user=";
//         }  

		let myinformationEditOrViewLink;
		let assignedunitgroupsEditOrViewLink;
		let NotificationManagementEditOrViewLink;

		if(queryString !== undefined ){
				myinformationEditOrViewLink="/myinformation?user="+`${queryString}`;
				assignedunitgroupsEditOrViewLink="/assignedunitgroups?user="+`${queryString}`;
				NotificationManagementEditOrViewLink="/NotificationManagementView?user="+`${queryString}`;
			}else{
				myinformationEditOrViewLink="/myinformation";
				assignedunitgroupsEditOrViewLink="/assignedunitgroups";
				NotificationManagementEditOrViewLink="/NotificationManagementView" ;
		}
		
		const notificationList = notificationData.length > 0 ? notificationData : staticNotificationTypeData;	
		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Loading Progress -->*/}
					<div id="page-content-wrapper">
						<div class="userManagementWrapper">
							<div class="userProfileHeader">
								<ul>
									<li>
										<Link to={myinformationEditOrViewLink} title={formatMessage({id: 'KC1916'})} onClick={(e)=> {e.trackTitle="My Information";this.trackEvent(e)}} ><FormattedMessage id='KC1916' /></Link>
										<Link to={assignedunitgroupsEditOrViewLink} title={formatMessage({id: 'KC1917'})} onClick={(e)=> {e.trackTitle="Assigned Unit Groups";this.trackEvent(e)}}><FormattedMessage id='KC1917' /></Link>										
										<Link to={{pathname:NotificationManagementEditOrViewLink, state : {fromViewAccountpageValue: true}}} title={formatMessage({id:'KC0109'})} class="active"><FormattedMessage id='KC0109' /></Link>
										{/* <Link to="/mySubscriptionView" title="Subscription Management">Subscription Management</Link> */}
										<Link to="/termsPricingCondition" title={formatMessage({id: 'KC2439'})} ><FormattedMessage id='KC2439' /></Link>
									</li>
								</ul>
							</div>
							<form class="notificationTypeForm">
								<div class="NotifnMgmtTable">
									<ul class="tHead">
										<li><FormattedMessage id='KC0133' /></li>
										<li><FormattedMessage id='KC0134' /></li>
										<li><FormattedMessage id='KC0136' /></li>
										<li>
										<div class={`customCheckbox customCheckboxWOlabel disabled ${this.state.isEmailIndeterminateSelect ? "selectAllDiv" : "" } `}>
												<input disabled="true"  onChange={(e) => this.handleALLSelectedItems(e,"EMAIL")} id="selectEditAll" type="checkbox" value={"EMAIL"} checked={this.state.isAllEMAILChkboxSelected}/>
												<label for="selectEditAll">&nbsp;</label>
											</div>
										</li>
									</ul>
									{Object.keys(notificationList).map((item, i) => (
										<ul class="tBody">
											<li>{ notificationList[i].preference_name === "offlineDeviceNotify" ? <FormattedMessage id="KC1663"/> : notificationList[i].preference_name === "Non-critical Errors" ? <FormattedMessage id="KC0137"/>  : notificationList[i].preference_name === "Critical Errors" ? <FormattedMessage id="KC2970"/> : notificationList[i].preference_name === "Operational Events" ? <FormattedMessage id="KC2971"/> : notificationList[i].preference_name === "Informative Events" ? <FormattedMessage id="KC2972"/> : notificationList[i].preference_name === "Resolved Errors" ? <FormattedMessage id="KC2973"/> : notificationList[i].preference_name}</li>
											{notificationList[i].preference_name === "Unit Offline" || notificationList[i].preference_name === "offlineDeviceNotify" ? <li>
													<div class="input-group">
														{notificationList[i].value}
													</div>
													<span class="inputGroupLabel"><FormattedMessage id='KC0280' /></span>
												</li> : <li style={{color:'#96a8bb'}}><FormattedMessage id='KC1747' /></li>}
											<li>&nbsp;</li>
											<li>
												<div class="customCheckbox customCheckboxWOlabel disabled">
													<input disabled="true" id={notificationList[i].Id +"Email"} name={notificationList[i].Id +"Email"}  checked={notificationList[i].isEmailChecked} type="checkbox" value={"EMAIL"} />
													<label for={notificationList[i].Id +"Email"} >&nbsp;</label>
												</div>
											</li>
										</ul>
									))}										
								</div>
							</form>
						</div>
					</div>
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal}/>				
				</div>
				<UserManagementHeader headerName="User Profile" headerNameStringID="KC0106" previousPage={queryString !== undefined  ? "User List" : "User Profile"} activeClass="UserProfileNav"/>	
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotificationManagementView));