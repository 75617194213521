import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import authService from '../../../service/authService';
import UserManagementHeader from '../../layoutSection/userManagementHeader'
import { UnitInfo, HACCPManagementAPI } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import _ from 'lodash';
import $ from 'jquery';
import appconstants from '../../../utils/appConstants';
import { BRAND_ID_VALUE,BASECOUNTRY_ID } from '../../../utils/appConstants';
import Tooltip from 'react-tooltip-lite';
import OfflineMediaImg from '../../../images/icons/offlineT.svg';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';

class HACCPAssignUpdateList extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(probs) {
		super(probs);
		this.state = {
			units_list: [],
			total_unit_count: 0,
			prev_page: 0,
			next_page: 2,
			data: [],
			pages: null,
			loading: true,
			unitsSelected: 0,
			unitsOffline: 0,
			userSelectAll: false,
			isIndeterminateSelect: false,
			userChecked: [],
			userCheckedData: [],
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			loaderIconVisible: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			}
		}

		this.onSortedChange = this.onSortedChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
		this.postData = this.postData.bind(this);
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/haccpRetrieveStatus`);
	}

	closeModel = () => {
		this.setState({ error_modal: { open: false } })
	}

	fetchData(state, instance) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState({ loading: true });
		// Request the data however you want.  Here, we'll use our mocked service we created earlier
		this.requestData(
			state.pageSize,
			state.page,
			state.sorted,
			state.filtered
		).then(res => {
			// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
			this.setState({
				data: res.rows,
				pages: res.pages,
				loading: false
			});
		});
	}

	onSortedChange = (a, b, desc) => {
		this.setState({
			prev_page: 0,
			next_page: 2
		});
		return 0;
	};

	onSuccessCloseModal = () => {
		this.props.history.push(`/haccpRetrieveStatus`);
	}

	onBackClickHandler = () => {
		this.props.history.push(`/haccpLogLibrary`);
	}

	closeModel() {
		this.setState({
			error_modal: {
				open: false
			}
		})
		this.props.history.push(`/haccpRetrieveStatus`);
	}

	getBrandId = () => {
		let brandName = localStorage.getItem("brandName");
		return BRAND_ID_VALUE[brandName] || 0;
	}
	componentDidUpdate(prevState) { 
        this.updateDimensions(); 
    }
	updateDimensions() { 
		const windowHeight = $(window).height();
        const assignUListSrlBar = $(".assignUListSrlBar");
        assignUListSrlBar.css('height', windowHeight - assignUListSrlBar.offset().top);
	} 
	componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions);
		document.body.style.backgroundColor = "#F2F2F2";
		try {
			this.requestData();
		} catch (error) {
			//Error Goes Here
		}
		 
	}	 

	pageChange = (arg, next, prev) => {
		if (arg > -1) {
			this.setState({
				prev_page: arg,
				next_page: (arg + 1) + 1
			});
		}
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			}
		});
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: data.message ? data.message : ""
					}
				});
			}
		} else {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1168"
				}
			});
		}
	}

	handleChange = () => {
		var userSelectAll = !this.state.userSelectAll;
		this.setState({ userSelectAll: userSelectAll });
		var checkedCopy = [];
		let units = [...this.state.data];

		units && units.length > 0 && units.map(item => {
			item.checked = item.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" ? userSelectAll : false;
			return checkedCopy.push(item.checked);
		});

		this.isSelectAssign(units);
		let unitsOfflineCount = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.CONNECT_STATE === "Offline");
		let unitsSelected = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);

		if (userSelectAll) {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				unitsSelected: unitsSelected.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: unitsSelected,
				isIndeterminateSelect: false,
			});
		}
		else {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				unitsSelected: 0,
				unitsOffline: 0,
				userCheckedData: [],
				isIndeterminateSelect: false,
			});
		}
		this.setState({
			...this.getCheckBoxstate(units.filter((c)=>c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED").length, unitsSelected.length)
		})
	};

	
	getCheckBoxstate(ListCount, SelectedCount) {
		if (SelectedCount == ListCount) {
			return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (SelectedCount == 0 && ListCount != 0) {
			return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (ListCount >= SelectedCount) {
			return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange = (e, index, rowData) => {
		var checkedCopy = this.state.userChecked;
		checkedCopy[index] = !this.state.userChecked[index];
		if (checkedCopy[index] === false) {
			this.setState({ userSelectAll: false });
		}
		let checkAll = [];

		const units = [...this.state.data];
		units[index] = { ...units[index] };
		units[index].checked = e.target.checked;

		checkAll = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && (c.checked === false || c.checked === undefined));
		let checked = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);
		let unitsOfflineCount = checked.filter(c => c.CONNECT_STATE === "Offline");
		let unitRegistedCount = units.filter(c => c.PROVISIONING_STATUS === "UNIT_REGISTERED").length

		if (checkAll.length === 0) {
			this.setState({
				data: units,
				userSelectAll: true,
				userChecked: checkedCopy,
				unitsSelected: checked.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: checked,
				...this.getCheckBoxstate(parseInt(units.length) -parseInt(unitRegistedCount) 
			,checked.length)
			});
		}
		else {
			this.setState({
				data: units,
				userSelectAll: false,
				userChecked: checkedCopy,
				unitsSelected: checked.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: checked,
				...this.getCheckBoxstate(parseInt(units.length) -parseInt(unitRegistedCount) 
			,checked.length)
			});
		}

		this.isSelectAssign(checked);
	};

	isSelectAssign = (units) => {
		let checkAllTrue = [];
		checkAllTrue = units.filter(c => c.checked === true);

		if (checkAllTrue.length > 0) {
			$("#btnfinish").addClass("btn-default-text activeState");
			$("#btnfinish").removeClass("disabled");
		}
		else {
			$("#btnfinish").removeClass("activeState");
			$("#btnfinish").addClass("disabled");
		}
	}

	requestData = async () => {
		let brandId = localStorage.getItem("selectedbrandID");
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.softwareFilters);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
		}

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];

		selectedCountryList.dataSource.map(c => {
			selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			selectedMachineTypes.push(c.ID)
		});

		var d = document.getElementById("spannavNotifiStatus");
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}
		if (selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0) {
			cgids = selectedCgids.join(',');
			d.className = "navNotifiStatus";
		}
		else {
			d.className = "";
		}

		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		let url = `${UnitInfo.list}`;
		let apiUrl = url;
		let res = {
			rows: [],
			pages: 0
		};
		this.setState({ loaderIconVisible: true });
		await http.get(apiUrl,
			{
				headers: {
					'Content-Type': 'application/json',
					'cgid': cgids,
					'cid': selectedCids.join(','),
					'brandid': selectedBrands.join(',') || brandId,
					'model': selectedModels.join(','),
					'machinetype': selectedMachineTypes.join(','),
					'sortby': sortedID,
					'sorttype': sortedDesc,
					'basecountry': BASECOUNTRY_ID,
					'showOnlyActiveUnits': true
				},
				data: {}
			}).then(response => {
				let { units, numberOfPages } = response.data;
				units = UnitBuilder(units);

				var checkedCopy = [];
				var userSelectAll = this.state.userSelectAll;
				units.forEach(function (e, index) {
					checkedCopy.push(userSelectAll);
				});

				this.setState({
					loaderIconVisible: false,
					data: units,
					userChecked: checkedCopy,
					userSelectAll: userSelectAll
				});

			}).catch(err => {
				this.setState({ loaderIconVisible: false, })
			});
	};

	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	onFinishClickHandler() {
		let userCheckedData = this.state.userCheckedData;
		if (userCheckedData.length > 0) {
			const unitList = [];
			userCheckedData.map(item => {
				return unitList.push(
					{
						"thingArn": item.THING,
						"productId": item.PRODUCT_ID,
						"cloudUnitName": item.CLOUD_UNIT_NAME,
						"unitTimeZone": item.TimeZone_Standard
					}
				)
			});
			
			console.log('CALLED FINISHED');
			let req = {
				"thingList": unitList,
				"brandid": 6
			}
	
			this.setState({ loaderIconVisible: true });
			this.postData(HACCPManagementAPI.logLibraryUrl, req).then(response => {
				WoopraEvents(`${Constants.ADD_LOG_LIBRARY}`);
				this.props.history.push(`/haccpRetrieveStatus`);
			}).catch(err => {
				//Error Goes Here
				WoopraEvents(`${Constants.ADD_LOG_LIBRARY_FAILED}`);
				this.showError(err);
			});
		}
		else {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1577"
				},
			});
		}
	}

	showError = (err) => {
		let { response } = err;
		let { data } = response;
		let { body } = data;
		if (response && response.status === 400) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: body.message ? body.message : ""
				}
			});
		}
		else if (response && response.status === 500) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: body.message ? body.message : ""
				},
			});
		}
		else {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1083"
				}
			});
		}
	}

	postData = (url, reqData) => {
		this.setState({
			loaderIconVisible: true
		});
		let storageCustomerId = localStorage.getItem("customerId");
		return new Promise((resolve, reject) => {

			http.post(url
				, reqData,
				{
					headers: {
						'Content-Type': 'application/json',
						'user': localStorage.getItem("user"),
						'basecountry': BASECOUNTRY_ID,
						'customerid': storageCustomerId,
					}
				}
			)
				.then(response => {
					let { data } = response;
					let { httpCode, responseCode, message, stringID } = data;
					if (response && response.status === 200) {
						this.setState({
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: (stringID || message) || ""
							},
						});
					}
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { data, sortColumn, error_modal, success_modal, loaderIconVisible } = this.state;
		let userProfileAuthFeature = authService.getFeature("/haccpLogLibrary");
		let orderId = 1;

		let columns = [
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0101", isNbSp: true },
			{ path: "BRAND_NAME", labelStringID: "KC0062" },
			{ path: "PRODUCT_TYPE", labelStringID: "KC0102" },
			{ path: "MODEL_NAME", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "UNIT_NUMBER", labelStringID: "KC0674" },
			{ path: "location_name", labelStringID: "KC0050" },
			{ path: "within_location", labelStringID: "KC0061" },
			{ path: "CONNECT_STATE", labelStringID: "KC0523", isNbSp: true },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		let unitList = data;

		unitList.forEach(item => {
			item.order_id = orderId++
		});

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);

		return (
			<div id="adminWrapper">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">
					<div className="unitRegistrationWrapper">
						<div className="addUnitHeader">
							<ul>
								<li>
									<a href="javascript:void(0)" onClick={() => this.onBackClickHandler()} title={formatMessage({ id: 'KC0989' })} className="backIcon">&nbsp;</a>
								</li>
								<li className="txtboldmsg">
									<FormattedMessage id= 'KC1744'/>
								</li>
								<li>
									<Link id="btnfinish" to="#" className="disabled" onClick={() => this.onFinishClickHandler()} title={formatMessage({ id: 'KC0056' })}><FormattedMessage id= 'KC0056'/></Link>
								</li>
							</ul>
						</div>
						<div className="selectedUnitHeader">
							<ul>
								{/* <li><FormattedMessage 
										id="KC1570"
										values={{ selectedCount: this.state.unitsSelected, offlineCount: this.state.unitsOffline}}/>
								</li> */}
								<li>{formatMessage({id: 'KC1570'}).replace('{selectedCount}',this.state.unitsSelected).replace('{offlineCount}',this.state.unitsOffline)}</li>
								<li>
									<Link to="/assignUpdateList" className="btn btn-default-text assignunitList active" >&nbsp;</Link>
								</li>
							</ul>
						</div>
						<div className="SWlistUnits">
							<div className="SWlistUnitsTable">
								<TableHeader
									sortColumn={sortColumn}
									onSort={this.onSort}
									selectAllChange={this.handleChange}
									columns={columns}
									isSelectAll={this.state.userSelectAll}
									isIndeterminate={this.state.isIndeterminateSelect} />
							</div>
							<div className="assignUListSrlBar">
							<div className="SWlistUnitsTable">
								{
									unitList.map((item, i) => {
										return (
											<ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody "} key={i}>
												<li>{i + 1}</li>
												<li><img src={item.Brand_Logo} /></li>
												<li>{item.BRAND_NAME}
													<div className="unitsListSM">{item.PRODUCT_TYPE},{item.MODEL_NAME}</div>
													<div className="unitsListSML">{item.location_name}</div></li>
												<li>{item.PRODUCT_TYPE}</li>
												<li>{item.MODEL_NAME}</li>
												<li>{item.UNITID} <span className="unitsListAN"><FormattedMessage id="KC0674"/> <b>{item.UNIT_NUMBER}</b></span></li>
												<li>{item.UNIT_NUMBER}</li>
												<li>{item.location_name}</li>
												<li>{item.within_location}</li>
												<li>
													{ item.CONNECT_STATE === 'Offline'  && <img src = { OfflineMediaImg} />}
												</li>
												<li>{item.PROVISIONING_STATUS !== "CERTIFICATE_RECEIVED" ? <Tooltip content={formatMessage({ id: 'KC1735' })} direction="left" className="targetdisabled">
													<div className={"customCheckbox customCheckboxWOlabel disabled"}>
														<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
															checked={item.checked}
															onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
														<label for={item.order_id - 1}></label>
														</div></Tooltip> 
														: <div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
														<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
															checked={item.checked}
															onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
														<label for={item.order_id - 1}></label>
													</div>}
												</li>
											</ul>
										)
									}
									)
								}
							</div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={unitList !== undefined ? unitList.length : 0} classname={'norecordsfoundbluetext'} />
							</div>
						</div> 
					</div>
				</div>
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
				<UserManagementHeader headerName="HACCP Management" headerNameStringID="KC0636" activeClass="haccpNav" previousPage="HACCP List" filterKey={appconstants.softwareFilters} history={this.props.history} />
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel,
		storeInstalledItemInfo: state.storeInstalledItemInformation.storeInstalledItemInformation,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HACCPAssignUpdateList));